import React, {useContext} from "react";

import { ModalContext } from "../useModal";
const IncidentData = () => {
  const { selectedEntity } = useContext(ModalContext);

    return (<div className="site-tab-content">     
    This is the Incident Data Page for {selectedEntity.site}
    </div>);
}

export default IncidentData;