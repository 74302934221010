import { useContext, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EntityType } from "../../types";
import { emptyLocation, paginationLabels } from "../../constants";
import { ModalContext } from "../useModal";
import { EntityAndManagerContext } from "../EntityAndManagerProvider";
import EmptyState from "../EmptyState";
import EditEntityModal from "../EditEntityModal";
import { useLocalStorage } from "src/utils/use-local-storage";
import { Icon } from "@amzn/awsui-components-react";
import {SiteContext} from "./useNewTabbedView";


export type EntityTableProps = {
  clearMessages: () => void;
};

type ContentDisplayItem = {
  id: string;
  visible: boolean;
};

function getMatchesCountText(count: number | undefined) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export default ({ clearMessages }: EntityTableProps) => {
  const { showModal, selectedEntity, setSelectedLocation } = useContext(ModalContext);
  const {setInTabbedView} = useContext(SiteContext);
  const { setIsEditing, setSelectedRegion, setSelectedAOR, getMgrFullName, entities } = useContext(EntityAndManagerContext);
  const [selectedItems, setSelectedItems] = useState<EntityType[]>([]);
  const areButtonsDisabled = selectedItems.length === 0;

  function getLoadingState(entities: any[]): boolean {
    return entities.length === 0;
  }


  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      { id: "site", visible: true },
      { id: "region", visible: true },
      { id: "country", visible: true },
      { id: "rsm", visible: true },
      { id: "asm", visible: true },
      { id: "score", visible: true },
      { id: "survey_status", visible: true },
    ]
  };

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number;
    contentDisplay: readonly ContentDisplayItem[];
  }>('PrimaryView-EntityTable-Preferences', DEFAULT_PREFERENCES);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(entities, {
    filtering: {
      empty: (
        <EmptyState
          title="No Entities Found"
          action={<Button>Create Entity</Button>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.ASM === filteringText
          || getMgrFullName(item.ASM).toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.RSM === filteringText
          || getMgrFullName(item.RSM).toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.region.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.country.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.site.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase());
      },
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });
  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={(evt) => {
          const { detail } = evt;
          setSelectedItems(evt.detail.selectedItems);
          setSelectedLocation(evt.detail.selectedItems[0]);
          clearMessages();
        }}
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
        }}
        columnDefinitions={[
          {
            id: "site",
            header: "Site name",
            cell: (e: EntityType) => <Button variant="link" onClick={() => {
              setSelectedLocation(e);
              setInTabbedView(true);
            }}>{e.site}</Button>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "region",
            header: "Region",
            cell: (e: EntityType) => e.region,
            sortingField: "region",
          },
          {
            id: "country",
            header: "Country",
            cell: (e: EntityType) => e.country,
          },
          {
            id: "rsm",
            header: "Regional Security Manager",
            cell: (e: EntityType) => getMgrFullName(e.RSM),
          },
          {
            id: "asm",
            header: "Area Security Manager",
            cell: (e: EntityType) => getMgrFullName(e.ASM),
          },
          {
            id: "score",
            header: "Overall Score",
            cell: (e: EntityType) => <Box textAlign="center"> {e.site_risk_avg} </Box>,
          },
          {
            id: "survey_status",
            header: "Controls Survey",
            cell: (e: EntityType) => {
              let status = "Not submitted";
              let color = "red";
              if (e.survey_partially_completed) {
                color = "orange";
                status = "In progress";
              } else if (e.survey_completed) {
                status = "Completed";
                color = "green"
              }
              return (<Box textAlign="center"><span style={{color: color}}>{status}</span></Box>);
            },
          },
        ]}
        columnDisplay={preferences.contentDisplay}
        enableKeyboardNavigation
        items={itemsFromUseCollection}
        loading={getLoadingState(entities)}
        loadingText="Loading entities"
        selectionType="single"
        trackBy="site"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find an entity"
            filteringAriaLabel="Filter entities"
          />
        }
        header={
          <Header
            counter={
              selectedItems.length
                ? "(" + selectedItems.length + " / " + preferences.pageSize + ")"
                : "(" + preferences.pageSize + ")"
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={(evt) => {
                    if (evt.detail.id === "view_details_button") {
                      setIsEditing(false);
                      showModal("viewEntity");
                    } else if (evt.detail.id === "edit_details_button") {
                      setIsEditing(true);
                      showModal("editEntity");
                    } else if (evt.detail.id === "controls_survey_button") {
                      setIsEditing(false);
                      showModal("controlsSurvey");
                    } else if (evt.detail.id === "health_check_button") {
                      setIsEditing(false);
                      showModal("healthCheck");
                    } else if (evt.detail.id === "likelihood_survey") {
                      setIsEditing(false);
                      showModal("likelihoodSurvey");
                    }
                    setSelectedItems([]);
                  }}
                  items={[
                    {
                      text: "Controls Survey",
                      id: "controls_survey_button",
                      disabled: areButtonsDisabled,
                    },
                    {
                      text: "View details",
                      id: "view_details_button",
                      disabled: areButtonsDisabled,
                    },
                    {
                      text: "Edit",
                      id: "edit_details_button",
                      disabled: areButtonsDisabled,
                    },
                    {
                      text: "Health Check",
                      id: "health_check_button",
                      disabled: areButtonsDisabled,
                    },
                    {
                      text: "Likelihood Survey",
                      id: "likelihood_survey",
                      disabled: areButtonsDisabled,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  onClick={(_event) => {
                    setSelectedLocation(emptyLocation);
                    setSelectedRegion("");
                    setSelectedAOR("");
                    showModal("editEntity");
                  }}
                >
                  <Icon name="add-plus" />
                </Button>
              </SpaceBetween>
            }
          >
            Entities
          </Header>
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => {
              const { pageSize, contentDisplay } = detail;
              if (typeof pageSize === 'number' && contentDisplay) {
                setPreferences({ pageSize, contentDisplay });
              }
            }}
            pageSizePreference={{
              title: "Page Size",
              options: [
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
              ],
            }}
            contentDisplayPreference={{
              options: [
                {
                  id: "site",
                  label: "Site Name",
                  alwaysVisible: true,
                },
                { id: "region", label: "Region" },
                { id: "country", label: "Country" },
                { id: "rsm", label: "RSM" },
                { id: "asm", label: "ASM" },
                { id: "score", label: "Overall Score" },
                { id: "survey_status", label: "Controls Survey" },
              ],
            }}
          />
        }
      />
      <EditEntityModal entity={selectedEntity} />
    </>
  );
};
