import React, {useContext} from "react";
import { ModalContext } from "../useModal";

const ControlsRegister = () => {
  const { selectedEntity } = useContext(ModalContext);
  return <div className="site-tab-content">
    This is the Controls Register for {selectedEntity.site}
    </div>
}

export default ControlsRegister;