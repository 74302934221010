// 12/09/2024 - We are moving toward Ramsey's new tabbed design.  To save network calls, I'm reusing the selectedEntity from useModal, but the selection behavior
// needs to be a little different.  Rather than using the selectedItems in EntityTable in modals, when a site is clicked, switch to the new tabbed view.  We'll figure out
// what to do with the various modals that use selectedEntity later.

import React, {createContext, useContext, useEffect, useState} from "react";

export type NewTabbedViewContextType = {
    inTabbedView: boolean,
    setInTabbedView: (_useTabs: boolean) => void
}

export const SiteContext = createContext<NewTabbedViewContextType>({
    inTabbedView: false,
    setInTabbedView: (_useTabs: boolean) => {}
})

export const SiteContextProvider = (props: {children: JSX.Element}) => {
    const [inTabbedView, setInTabbedView] = useState(false);
    return (<SiteContext.Provider value={{ inTabbedView, setInTabbedView}}>{props.children}</SiteContext.Provider>);
}

