import React, {useContext} from "react";
import { ModalContext } from "../useModal";

const StrategicControlManagementPlan = () => {
  const { selectedEntity } = useContext(ModalContext);
    return (<div className="site-tab-content">     
    This is the Strategic Control Management Plan for {selectedEntity.site}
    </div>);
}

export default StrategicControlManagementPlan;