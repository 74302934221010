import React, { createContext, useContext, useEffect, useState } from 'react';
import { EntityType, HealthCheckRowType, HealthCheckDBType, IncidentType, SecurityManagerType } from 'src/types';
import { emptyIncident, emptyLocation, EmptyManager } from '../constants';
import { DefinitionsContext } from "./DefinitionsProvider";
import { RoleContext } from "./RoleProvider";
import { getBaseUrl } from "../utils";

export type ModalContextType = {
	
	isShowing: (s: string) => boolean;
	healthCheckResults: HealthCheckRowType[]
	
	hideModals: () => void;
	showModal: (s: string) => void;
	selectedEntity: EntityType;
	selectedIncidents: IncidentType[];
	selectedSecurityManager: SecurityManagerType | null;
	setSelectedIncidents: (incidents: IncidentType[]) => void;
	setSelectedLocation: (loc: EntityType) => void;
	setSelectedSecurityManager: (mgr: SecurityManagerType) => void;

}

const dummy: ModalContextType = {
	isShowing: (_modalId: string) => false,
	hideModals: () => { },
	healthCheckResults: [], 
	showModal: (_modalId: string) => { },
	selectedEntity: emptyLocation,
	selectedIncidents: [emptyIncident],
	selectedSecurityManager: null,
	setSelectedLocation: (_location: EntityType) => { },
	setSelectedIncidents: (_incident: IncidentType[]) => { },
	setSelectedSecurityManager: (_mgr: SecurityManagerType) => {}
	
}

const ModalContext = createContext<ModalContextType>(dummy);

const ModalContextProvider = (props: { children: JSX.Element }) => {
	const { token } = useContext(RoleContext);
	const { EffectivenessDefinitions } = useContext(DefinitionsContext);
	const [currentlyActiveModal, setCurrentlyActiveModal] = useState("");
	//"editing" means that you're working on an existing entity NOT whether your existing entity is in edit mode or view mode
	
	const [selectedEntity, setSelectedEntity] = useState(emptyLocation);
	const [selectedIncidents, setSelectedIncidents] = useState([emptyIncident]);
	const [selectedSecurityManager, setSelectedSecurityManager] = useState(EmptyManager);
	const [healthCheckResults, setHealthCheckResults] = useState<HealthCheckRowType[]>([]);

	function setSelectedLocation(location: EntityType) {
		setSelectedEntity(location);
	}

	function hideModals() {
		setCurrentlyActiveModal("");
	}

	function isShowing(modalId: string) {
		return currentlyActiveModal === modalId;
	}

	function showModal(modalId: string) {
		setCurrentlyActiveModal(modalId);
	}

	useEffect(() => {
        const fxn = async () => {
            if (selectedEntity.site !== "") {
                const res = await fetch(`${getBaseUrl()}/healthCheck?siteName=${selectedEntity.site}`,
                    { headers: new Headers({ "wowie": token }) });
                if (res.status !== 200) {

                    return;
                }
                const json = await res.json();
                const adjustedHealthCheck = json.healthCheck.map((hcDB: HealthCheckDBType ) => {
                    return {
                        ...hcDB,
                        effectiveness: EffectivenessDefinitions[hcDB.effectiveness]?.name,
                        controls_count: hcDB.controls_used + " / " + hcDB.controls_count,
                        risk_category_name : hcDB.risk_name_full,
                        risk_name: hcDB.sub_risk_full_name
                    };
                })
                setHealthCheckResults(adjustedHealthCheck);
            }
        }
        fxn();


    }, [selectedEntity.entityId]);

	return <ModalContext.Provider value={{
		isShowing,
		healthCheckResults,
		hideModals,
		showModal,
		selectedEntity,
		selectedIncidents,
		setSelectedIncidents,
		setSelectedLocation,
		selectedSecurityManager,
		setSelectedSecurityManager
	}}>{props.children}</ModalContext.Provider>;
}

export { ModalContext, ModalContextProvider }