import React, {useContext} from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import SiteLandingPage from "./SiteLandingPage/SiteLandingPage";
import ControlsRegister from "./ControlsRegister";
import IncidentData from "./IncidentData";
import HealthCheck from "./HealthCheck";
import RiskRegister from "./RiskRegister";
import StrategicControlManagementPlan from "./StrategicControlMgmtPlan";
import {SiteContext} from "./useNewTabbedView";

const SiteContainer = () => {
  const {setInTabbedView} = useContext(SiteContext);
  return (<Tabs
    tabs={[
      {
        label: "Site HP",
        id: "site-landing-page",
        content: <SiteLandingPage />
      },
      {
        label: "Health Check",
        id: "health-check",
        content: <HealthCheck />,
      },
      {
        label: "Risk Register",
        id: "risk-register",
        content: <RiskRegister />,
      },
      {
        label: "Controls Register",
        id: "controls-register",
        content: <ControlsRegister />
      },
      {
        label: "SCMP",
        id: "scmp",
        content: <StrategicControlManagementPlan />
      },
      {
        label: "Incident Data",
        id: "incident-data",
        content: <IncidentData />,
      },
      {
        label: <span onMouseDown={() => {
          setInTabbedView(false);
        }}>Home</span>,
        id: "home",
        content: "",
      }
    ]}
  />)
}

export default SiteContainer;