import React, { useContext } from "react";
import {
    Container,
} from "@amzn/awsui-components-react/polaris";

import { SiteContext } from "./useNewTabbedView";
import PrimaryView from "../PrimaryView";
import SiteContainer  from "./SiteContainer";

const HomePageSwitcher = () => {
    const { inTabbedView } = useContext(SiteContext);

    return (<Container>
        {!inTabbedView && <PrimaryView />}
        {inTabbedView && <SiteContainer />}
    </Container>)
}

export default HomePageSwitcher;