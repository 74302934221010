import { useContext, useEffect, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Icon, Modal, Popover, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useLocalStorage } from "src/utils/use-local-storage";
import { LikelihoodSurveyContext } from "./LikelihoodSurveyContext";
import { paginationLabels, emptyLocation, NOTES_MINIMUM_LENGTH } from "../constants";
import { ModalContext } from "./useModal";
import CancelWarning from "./CancelWarning";
import { IncidentLikelihoodSurveyResponse } from "../types";
import EmptyState from "./EmptyState";
import SelectLikelihood from "./SelectLikelihood";
import LessonsLearned from "./LessonsLearnedForm";
import { FlashMessageContext } from "./useFlashMessages";


function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
}

type ContentDisplayItem = {
    id: string;
    visible: boolean;
};

const LikelihoodSurveyModal = () => {
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [showChangesWarning, setShowChangesWarning] = useState(false);
    const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
    const { selectedEntity, isShowing, hideModals, setSelectedLocation } = useContext(ModalContext);
    const { state, dispatch, postSurveyAnswers } = useContext(LikelihoodSurveyContext);
    const [showLessons, setShowLessons] = useState(false);
    const [selectedItems, setSelectedItems] = useState<IncidentLikelihoodSurveyResponse[]>([]);
    const {setShowSuccess, setFlashMessage} = useContext(FlashMessageContext);

    const [preferences, setPreferences] = useLocalStorage<{
        pageSize: number;
        contentDisplay: readonly ContentDisplayItem[];
    }>("Likelihood_Table_Prefs", {
        pageSize: 50,
        contentDisplay: [
            { id: "sub_risk_full_name", visible: true },
            { id: "risk_applied", visible: true },
            { id: "location_count", visible: true },
            { id: "region_count", visible: true },
            { id: "global_count", visible: true },
            { id: "assessed_likelihood", visible: true }
        ]
    });

    useEffect(() => {
        if (state.successfullyUpdated) {
            setShowSuccess(true);
            setFlashMessage("Survey submitted!");
        }

    }, [state.successfullyUpdated]);

    const {
        items: itemsFromUseCollection,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(state.assessedLikelihoodsByRisk, {
        filtering: {
            empty: (
                <EmptyState
                    title="No Items Found"
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    action={
                        <Button onClick={() => actions.setFiltering("")}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });

    const resetWarningState = () => {
        setHasPendingChanges(false);
        setShowChangesWarning(false);
        hideModals();
        setHasSeenUnsavedChangesWarning(false);
        setSelectedLocation(emptyLocation);
    }

    return (
        <Modal
            onDismiss={() => hideModals()}
            visible={isShowing("likelihoodSurvey")}
            size="max"
            header={<CancelWarning showWarning={showChangesWarning} warningText="You have made changes that aren't saved. Click &quot;save&quot; to keep your changes or &quot;cancel&quot; to discard them."
            />}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={(_event) => {
                            if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
                                setShowChangesWarning(hasPendingChanges);
                                setHasSeenUnsavedChangesWarning(true);
                                return false;
                            }
                            resetWarningState();
                        }}>
                            Cancel
                        </Button>
                        <Button
                            disabled={showLessons}
                            onClick={(_event) => {
                                postSurveyAnswers();
                                resetWarningState();
                            }}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                }}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: ({ selectedItems }) =>
                        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                        } selected`,
                }}
                columnDefinitions={[
                    {
                        id: "sub_risk_full_name",
                        header: "Risk",
                        cell: (e: IncidentLikelihoodSurveyResponse) => e.sub_risk_full_name,
                        width: 495,
                        minWidth: 400,
                    },
                    {
                        id: "risk_applied",
                        header: "Risk Applied",
                        cell: (e: IncidentLikelihoodSurveyResponse) => <div style={{ display: "flex", flexDirection: "row" }}>
                            {!showLessons && <Toggle
                                onChange={({ detail }) => {
                                    setSelectedItems([e]);
                                    setHasPendingChanges(true);
                                    setShowLessons(!detail.checked);
                                    //If we're going from "applies" to "n/a", we don't update the risk_applies property till we insure that we got notes or lessons
                                    //But if we're going from "n/a" to "applies" go ahead and update
                                    if (detail.checked) {
                                        dispatch({
                                            Type: "UPDATE_RISK_APPLIES",
                                            Cargo: {
                                                subRiskId: e.sub_risk_id,
                                                riskApplied: 1,
                                            },
                                        });
                                    }
                                }}
                                checked={e.risk_applied === 1}
                            >
                                <span style={{ marginRight: "10px", width: "100px" }}>{e.risk_applied ? "Applies" : "N/A"}</span>
                            </Toggle>}

                            {!e.risk_applied &&
                                <Popover
                                    dismissButton={true}
                                    position="top"
                                    size="large"
                                    triggerType="custom"
                                    content={
                                        <LessonsLearned notes={e.notes} lessonsLearned={e.lessons_learned} onClose={(_notes, _lessonsLearned) => {
                                            setShowLessons(false);
                                        }} showButtons={false} />
                                    }
                                >
                                    <Icon name="status-info" />
                                </Popover>} </div>,
                        width: 200,
                        minWidth: 150,
                    },
                    {
                        id: "location_count",
                        header: "On site",
                        cell: (e: IncidentLikelihoodSurveyResponse) => {
                            if (showLessons && selectedItems[0].sub_risk_id === e.sub_risk_id) {
                                return (<LessonsLearned notes={e.notes} lessonsLearned={e.lessons_learned} onClose={(notes, lessonsLearned) => {
                                    setShowLessons(false);
                                    //A cheap way to make either notes or lessons learned a required field for purposes of toggling off a risk
                                    if (notes?.length < NOTES_MINIMUM_LENGTH && lessonsLearned?.length < NOTES_MINIMUM_LENGTH) {
                                        dispatch({
                                            Type: "UPDATE_RISK_APPLIES",
                                            Cargo: {
                                                subRiskId: e.sub_risk_id,
                                                riskApplied: 1,
                                            },
                                        });
                                    } else {
                                        dispatch({
                                            Type: "UPDATE_NOTES_AND_LESSONS_LEARNED",
                                            Cargo: {
                                                subRiskId: e.sub_risk_id,
                                                lessonsLearned: lessonsLearned,
                                                notes: notes
                                            },
                                        });

                                        dispatch({
                                            Type: "UPDATE_RISK_APPLIES",
                                            Cargo: {
                                                subRiskId: e.sub_risk_id,
                                                riskApplied: 0,
                                            },
                                        });
                                    }

                                }} />)
                            }
                            return e.location_count;
                        },
                        width: 450,
                        minWidth: 225
                    },
                    {
                        id: "region_count",
                        header: "Regional",
                        cell: (e: IncidentLikelihoodSurveyResponse) => (showLessons ? "" : e.region_count),
                    },
                    {
                        id: "global_count",
                        header: "Global",
                        cell: (e: IncidentLikelihoodSurveyResponse) => (showLessons ? "" : e.global_count),
                    },
                    {
                        id: "assessed_likelihood",
                        header: "Assessed",
                        cell: (e: IncidentLikelihoodSurveyResponse) => {
                            let assessedVal = state.assessedLikelihoodsByRisk.filter((alr) => alr.sub_risk_id === e.sub_risk_id)[0].rating;

                            return (showLessons ? "" :
                                <SelectLikelihood
                                    disabled={e.risk_applied === 0}
                                    value={assessedVal}
                                    onChange={(newVal) => {
                                        setHasPendingChanges(true);
                                        dispatch({
                                            Type: "UPDATE_ASSESSED_LIKELIHOOD",
                                            Cargo: {
                                                sub_risk_id: e.sub_risk_id,
                                                rating: parseInt(newVal),
                                            },
                                        });
                                        setHasPendingChanges(true)

                                    }}
                                />);
                        }
                    },
                ]}
                columnDisplay={preferences.contentDisplay}
                enableKeyboardNavigation
                items={itemsFromUseCollection}
                loadingText="Loading likelihood data"
                resizableColumns
                wrapLines
                trackBy="sub_risk_full_name"
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                        filteringPlaceholder="Find a risk"
                        filteringAriaLabel="Filter risks"
                    />
                }
                header={
                    <Header>
                        Likelihood Survey for {selectedEntity.site}
                    </Header>
                }
                pagination={
                    <Pagination {...paginationProps} ariaLabels={paginationLabels} />
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        onConfirm={({ detail }) => {
                            const { pageSize, contentDisplay } = detail;
                            if (typeof pageSize === 'number' && contentDisplay) {
                                setPreferences({ pageSize, contentDisplay });
                            }
                        }}
                        pageSizePreference={{
                            title: "Page Size",
                            options: [
                                { value: 10, label: "10" },
                                { value: 20, label: "20" },
                                { value: 50, label: "50" },
                                { value: 100, label: "100" },
                            ],
                        }}
                        contentDisplayPreference={{
                            options: [
                                { id: "sub_risk_full_name", label: "Risk", alwaysVisible: true },
                                { id: "location_count", label: "On site (incidents)" },
                                { id: "region_count", label: "Regional (incidents)" },
                                { id: "global_count", label: "Global (incidents)" },
                                { id: "assessed_likelihood", label: "Assessed", alwaysVisible: true },
                                { id: "risk_applied", label: "Risk Applied" },
                            ],
                        }}
                    />
                }

                selectedItems={selectedItems}
                selectionType="single"
            />
        </Modal>
    );
};

export default LikelihoodSurveyModal;
