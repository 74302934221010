import React, { useContext, useState } from "react";
import { Grid, Icon } from "@amzn/awsui-components-react";
import { ModalContext } from "../../useModal";
import SiteInfoReadOnly from "./SiteInfoReadOnly";
import SiteInfoEditMode from "./SiteInfoEditMode";
import { EntityAndManagerContext } from "src/components/EntityAndManagerProvider";


const SiteLandingPage = () => {
  const { selectedEntity } = useContext(ModalContext);
  const { site } = selectedEntity;
  const {isEditing, setIsEditing } = useContext(EntityAndManagerContext)

  return <div>
    <Grid
      gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}
    >
      <div className="site-landing-page">
        <div className="heading-with-aside">
          <h2>{site} <span onClick={(evt) => setIsEditing(!isEditing)}><Icon name="edit" variant={isEditing ? "disabled" : "subtle"} /></span></h2>
        </div>
        { isEditing ?  <SiteInfoEditMode /> : <SiteInfoReadOnly /> }
      </div>
      <div>
        <div className="fake-map"> map goes here </div>
      </div>
    </Grid>
  </div>

}

export default SiteLandingPage;