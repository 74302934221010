import React, { createContext, useContext, useState, useEffect } from "react";
import { emptyLocation, EmptyManager } from "../constants";
import { RoleContext } from "./RoleProvider";
import { ModalContext } from "./useModal";
import { EntityType, SecurityManagerType } from "src/types";
import { getBaseUrl } from "../utils"

const initialState = {
    aors: [""], //the aors for whatever region is selected
    allManagers: [EmptyManager],
    countries: [""], //countries of whatever aor is selected
    entities: [emptyLocation],
    error: "",
    getMgrFullName: (_alias: string) => "",
    isEditing: false,
    managers: [EmptyManager], //the managers for whatever region is selected
    isLoading: true,
    postOrPutChanges: (_entity: EntityType) => { },
    refreshEntitiesAndManagers: () => { },
    selectedRegion: "",
    setSelectedRegion: (_region: string) => { },
    setSelectedAOR: (_aor: string) => { },
    selectedAOR: "",
    setIsEditing: (val: boolean) => { },
    siteCountsByRegion: [{ region: "AMER", site_count: 0 }, { region: "APAC", site_count: 0 }, { region: "EMEA", site_count: 0 }]
};

export type EntityAndManagerContextType = typeof initialState;

export const EntityAndManagerContext = createContext(initialState);

export const EntityAndManagerProvider = (props: { children: JSX.Element }) => {
    const [entities, setEntities] = useState<EntityType[]>([]);
    const { hideModals, selectedEntity, setSelectedLocation } = useContext(ModalContext);
    const [isEditing, setIsEditing] = useState(false);
    const regionsEndpoint = `${getBaseUrl()}/regionsAorsCountries`;
    const { fakeLoggedInUser, loggedInUser, userIsRiskAdmin, roleFetchComplete, token } = useContext(RoleContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [aorsByRegion, setAorsByRegion] = useState({
        EMEA: new Set<string>(),
        AMER: new Set<string>(),
        APAC: new Set<string>()
    });
    const [aors, setAors] = useState([""]);
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedAOR, setSelectedAOR] = useState("");
    const [countries, setCountries] = useState([""]);
    const [allManagers, setAllManagers] = useState<SecurityManagerType[]>([EmptyManager]);
    //the list of managers for the currently-selected region
    const [managers, setManagers] = useState<SecurityManagerType[]>([]);
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [siteCountsByRegion, setSiteCountsByRegion] = useState([{ region: "AMER", site_count: 0 }, { region: "APAC", site_count: 0 }, { region: "EMEA", site_count: 0 }])

    interface StringDictionary {
        [key: string]: string[];
    }

    const [countriesByAOR, setCountriesByAOR] = useState<StringDictionary>({});

    const fetchEntities = async () => {
        if (!roleFetchComplete) {
            return;
        }
        let baseEntitiesUrl = `${getBaseUrl()}/entities`
        let entitiesUrl = baseEntitiesUrl;
        if (userIsRiskAdmin) {
            entitiesUrl = fakeLoggedInUser ? `${baseEntitiesUrl}?alias=${fakeLoggedInUser}` : baseEntitiesUrl;
        } else {
            entitiesUrl = `${baseEntitiesUrl}?alias=${loggedInUser}`;
        }
        const res = await fetch(entitiesUrl,
            { headers: new Headers({ "wowie": token }) });
        if (res.status !== 200) {
            setError("Could not load entities");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        setEntities(json.entities);
        setSiteCountsByRegion(json.siteCountByRegion);

        setIsLoading(false);
    }

    const fetchManagers = async () => {

        const managersUrl = `${getBaseUrl()}/managers`

        const res = await fetch(managersUrl,
            { headers: new Headers({ "wowie": token }) });
        if (res.status !== 200) {
            setError("Could not load entities");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        setAllManagers(json.managers);
        setIsLoading(false);
    }


    const fetchRegionsAorsAndCountries = async () => {
        const res = await fetch(regionsEndpoint,
            { headers: new Headers({ "wowie": token }) });
        if (res.status !== 200) {
            setError("Could not load regions and errors");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        const { data, aorsAndCountries } = json;
        const newAORsByRegion = {
            EMEA: new Set<string>(),
            AMER: new Set<string>(),
            APAC: new Set<string>(),
        };

        for (let i = 0; i < data.length; i++) {
            switch (data[i].region) {
                case "APAC":
                    newAORsByRegion.APAC.add(data[i].aor);
                    break;
                case "AMER":
                    newAORsByRegion.AMER.add(data[i].aor);
                    break;
                case "EMEA":
                    newAORsByRegion.EMEA.add(data[i].aor);
                    break;
            }
        }

        setAorsByRegion(newAORsByRegion);
        const newCountriesByAOR: Record<string, string[]> = {};
        for (let i = 0; i < aorsAndCountries.length; i++) {
            if (!newCountriesByAOR[aorsAndCountries[i].aor]) {
                newCountriesByAOR[aorsAndCountries[i].aor] = [];
            }
            newCountriesByAOR[aorsAndCountries[i].aor].push(aorsAndCountries[i].country)
        }
        setCountriesByAOR(newCountriesByAOR);

    }

    useEffect(() => {
        if (roleFetchComplete) {
            fetchEntities();
            fetchRegionsAorsAndCountries();
            fetchManagers();
            setNeedsRefresh(false);
        }

    }, [roleFetchComplete, needsRefresh, fakeLoggedInUser]);

    useEffect(() => {
        switch (selectedRegion) {
            case "APAC":
                setAors(Array.from(aorsByRegion.APAC));
                setManagers(allManagers.filter((mgr) => mgr.region === "APAC"));
                break;
            case "AMER":
                setAors(Array.from(aorsByRegion.AMER));
                setManagers(allManagers.filter((mgr) => mgr.region === "AMER"));
                break;
            case "EMEA":
                setAors(Array.from(aorsByRegion.EMEA));
                setManagers(allManagers.filter((mgr) => mgr.region === "EMEA"));
                break;
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (countriesByAOR[selectedAOR]) {
            setCountries(countriesByAOR[selectedAOR]);
        }
    }, [selectedAOR]);

    useEffect(() => {
        if (countriesByAOR[selectedEntity.aor]) {
            setCountries(countriesByAOR[selectedEntity.aor]);
        }
    }, [selectedEntity.site]);

    useEffect(() => {
        setSelectedRegion(selectedEntity.region);
        switch (selectedEntity.region) {
            case "APAC":
                setAors(Array.from(aorsByRegion.APAC));
                break;
            case "AMER":
                setAors(Array.from(aorsByRegion.AMER));
                break;
            case "EMEA":
                setAors(Array.from(aorsByRegion.EMEA));
                break;
        }
    }, [selectedEntity.site]);


    const sortFxn = (a: EntityType, b: EntityType) => {
        if (a.site < b.site) return -1;
        if (a.site > b.site) return 1;
        return 0;
    };

    const postOrPutChanges = async (entity: EntityType) => {
        const res = await fetch(`${getBaseUrl()}/entities`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: isEditing ? "PUT" : "POST",
            body: JSON.stringify(entity),
        });

        const json = await res.json();
        if (res.status === 200) {
            let newEntities = [...entities];
            if (isEditing) {
                // If we're updating an existing item, just remove it and then add the updated one
                const updatedEntity = newEntities.filter((ent) => ent.site === entity.site)[0];
                const indexToRemove = newEntities.indexOf(updatedEntity);
                newEntities.splice(indexToRemove, 1);
            }

            newEntities.push(entity);
            //I wouldn't normally filter on the client, but if they just set isActive to false, I don't want to refetch the entities again 
            const sorted = newEntities.filter((ent) => ent.isActive).sort(sortFxn);
            setEntities(sorted);
            setSelectedLocation(entity);
        } else {
            console.log(res);
        }
        hideModals();

    };

    const getMgrFullName = (alias: string) => {
        const mgr = allManagers.find((mgr) => mgr.alias === alias);
        if (mgr) {
            return `${mgr.full_name}`;
        }
        return "";
    }


    const refreshEntitiesAndManagers = () => {
        setNeedsRefresh(true);
    }

    return (<EntityAndManagerContext.Provider value={{
        aors, allManagers, countries, error, entities, getMgrFullName, isEditing, isLoading, managers, postOrPutChanges, refreshEntitiesAndManagers,
        setIsEditing, selectedRegion, setSelectedRegion, selectedAOR, setSelectedAOR, siteCountsByRegion
    }}>
        {props.children}
    </EntityAndManagerContext.Provider>)
}