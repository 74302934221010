import {createContext, useState} from "react";

const initialState = {
    flashMessage: "",
    setFlashMessage: (s: string) => {},
    resetMessages: () => {},
   showSuccess: false,
   setShowSuccess: (s: boolean) => {}
}

export const FlashMessageContext = createContext(initialState);

export const FlashMessageProvider = (props: { children: JSX.Element }) => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
  
  
  
    const resetMessages = () => {
      setShowSuccess(false);
      setFlashMessage('');
    };

    return (<FlashMessageContext.Provider value={{flashMessage, setFlashMessage, resetMessages, showSuccess, setShowSuccess}}>{props.children}</FlashMessageContext.Provider>)

}