import React, { useContext, useState } from "react";
import EntityTable from "./HomePage/EntityTable";
import TriagedIncidentsTable from "./incidents/TriagedIncidentsTable";
import { FlashMessageContext } from "./useFlashMessages";
import { SiteContext } from "./HomePage/useNewTabbedView";

import {
    Container,
    Header,
} from "@amzn/awsui-components-react/polaris";

const PrimaryView = () => {
    const { resetMessages } = useContext(FlashMessageContext);

    return (<Container>
        <Container
            header={
                <Header variant="h2">
                    <span className="poseiden">ACS Global Sites</span>
                </Header>
            }
        >
            <EntityTable clearMessages={resetMessages} />
        </Container>


        <Container
            header={<Header variant="h2"><span className="poseiden">Incidents Library</span></Header>} variant="stacked"
        >
            <TriagedIncidentsTable showEditBtn={false} />
        </Container>
    </Container>);
};

export default PrimaryView;